.ag-theme-material {
    font-family: Roboto, sans-serif;
    font-size: 8pt;
    --column-border-style: 1px solid rgb(165, 165, 165);
}

.ag-theme-material .ag-cell.ag-cell-inline-editing {
    padding: 24px;
    height: 100%;
}

.treeCellValue {
    padding-left: 5px;
    padding-top: 3px;
}

.ag-row-selected {
    /* border: 1px solid darkviolet; */
}

.ag-theme-material .ag-row-hover {
    border: dashed 1pt black;
}

.ag-text-field-input {
    background-color: white !important;
}

.ag-layout-normal .ag-body-container {
    display: flex;
    margin-bottom: -2px;
    position: relative;
}

.ag-theme-material .ag-ltr .ag-cell {
    display: inline-flex;
    line-height: 1.4;
    padding: 0 1px; /* To improve readability of right and left aligend cells which are next to each other */
    border-right: solid lightgray 1px;
}

.ag-body-viewport {
    background: rgba(255, 255, 255, 0) !important;
}

.ag-body-viewport-wrapper {
    background: rgba(255, 255, 255, 0) !important;
}

.ag-body {
    background: rgba(255, 255, 255, 0) !important;
}

/* ag-grid sidebar uses space even when not displaying anything, so this style is needed*/
.ag-side-bar {
    display: none;
}

.ag-header-container {
    border-bottom: var(--column-border-style);
}

.ag-header-row {
    border-right: var(--column-border-style) !important;
}

.ag-header-cell:not(.ag-header-group-cell-no-group) {
    border-right: var(--column-border-style);
    border-top: var(--column-border-style);
    border-bottom: var(--column-border-style);
}

.ag-header-group-cell {
    border-right: var(--column-border-style);
    border-top: var(--column-border-style);
    border-bottom: none; /*3px solid rgb(165, 165, 165);*/
}

.ag-header-group-cell-no-group {
    border-right: var(--column-border-style) !important;
    border-top: none;
    border-bottom: none; /*3px solid rgb(165, 165, 165);*/
}

.ag-header-cell:not(.matrixColumnHeader) {
    border-top: var(--column-border-style) !important;
    padding: 0 5px 0 5px !important;
}

.matrixColumnHeader {
    padding: 0 5px 0 5px !important;
    border-top: var(--column-border-style) !important;
    border-right: var(--column-border-style) !important
}

.ag-header-group-text {
    min-width: 10px;
}

.ag-header-cell-label {
    /*Necessary to allow for text to grow vertically*/
    height: 100%;
    padding: 0 !important;
}

.matrixRowGroupHeader {
    border-right: var(--column-border-style) !important;
    border-bottom: none; /*3px solid rgb(165, 165, 165);*/
}

.matrixColumnHeaderRotated .ag-cell-label-container {
    display: flex;
    flex-direction: column;
    border-bottom: 7px solid transparent;
}

/* MO-2563 drag columns does not work on space above header text */
.ag-cell-label-container {
    height: 100%;
}

/* MO-3885 draggable area should be full cell, not only part filled with content, thus added width: 100% to ag-grid style */
.ag-cell-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.matrixColumnHeaderRotated .ag-header-cell-label {
    display: flex;
    flex-direction: column-reverse;
    border-bottom: 7px solid transparent;
}

.matrixColumnHeaderRotated .ag-header-cell-text {
    /*Force the width corresponding at how much width
    we need once the text is laid out vertically*/
    width: 20px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    line-height: 2em;
    /* spacing between bottom line and beginning of text */
    margin-bottom: 5px;
}

.matrixColumnHeaderRotated .ag-header-group-cell-label {
    /*Force the width corresponding at how much width
    we need once the text is laid out vertically*/
    width: 20px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    line-height: 2em;
}

.columnGroupShowPlaceholder {
    border-top: none !important;
}

.matrixColumnHeaderColorIndex0 {
    background: #E8EDF4 !important;
    color: #000000 !important;
}

.matrixColumnHeaderColorIndex1 {
    background: #D8E0EC !important;
    color: #000000 !important;
}

.matrixColumnHeaderColorIndex2 {
    background: #C3CFE1 !important;
    color: #000000 !important;
}

.matrixColumnHeaderColorIndex3 {
    background: #ABBCD5 !important;
    color: #000000 !important;
}

.matrixColumnHeaderColorIndex4 {
    background: #9DB1CF !important;
    color: #000000 !important;
}

.matrixColumnHeaderColorIndex5 {
    background: #869FC4 !important;
    color: #000000 !important;
}

.matrixColumnHeaderColorIndex6 {
    background: #7692BC !important;
    color: #000000 !important;
}

span.ag-cell-wrapper.ag-row-group {
    display: flex;
    align-items: center;
}

.ag-row-group-leaf-indent {
    /* margin should egal size of ag-icon -> fontsize */
    margin-left: 18px !important;
}

.ag-group-expanded {
    margin-right: 0 !important;
}

.ag-group-contracted {
    margin-right: 0 !important;
}

.ag-row-group-indent-1 {
    padding-left: 24px !important;
}

.ag-row-group-indent-2 {
    padding-left: 48px !important;
}

.ag-row-group-indent-3 {
    padding-left: 72px !important;
}

.ag-row-group-indent-4 {
    padding-left: 96px !important;
}

.ag-row-group-indent-5 {
    padding-left: 120px !important;
}

.ag-row-group-indent-6 {
    padding-left: 144px !important;
}

span.ag-group-checkbox.ag-invisible {
    display: none;
}

/* make ag-grid tree cell full width even if no content, MO-1037 */
span.ag-group-value {
    width: inherit;
}

.ag-overlay-no-rows-wrapper {
    justify-content: left;
    align-items: flex-start;
}

.ag-status-bar {
    height: 22px;
}
.ag-status-name-value {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    height: 20px;
}
